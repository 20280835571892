<template>
  <div class="home">
    <div class="illustration">
      <img alt="illustration" src="@/assets/images/appstore.png" />
    </div>
    <div class="download">
      <div class="content">
        <!-- <h1>mybabybook</h1> -->
        <div class="description">
          <strong>My Baby Book</strong> {{ $t("is") }}
        </div>
        <h3>{{ $t("downloadNow") }}</h3>
        <div class="btns">
          <div class="app-store">
            <a
              href="https://apps.apple.com/us/app/mybabybook-photos-and-memories/id1575310857#?platform=iphone"
            >
              <img
                alt="link apple app store"
                src="@/assets/images/app-store.png"
              />
            </a>
          </div>
          <div class="play-store">
            <a
              href="https://play.google.com/store/apps/details?id=com.mybabybookapp.mybabybook_app"
            >
              <img
                alt="link google play store"
                src="@/assets/images/google-play.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style scoped>
.home {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.home div.illustration,
.home div.download {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 50%;
}
.home div.illustration img {
  background-color: #fff;
  padding: 8px;
  margin: 0 auto;
  width: 160px;
  border-radius: 16px;
}
.home div.download h1 {
  font-size: 24px;
  color: #c96fd8;
}
.home div.download .btns {
  display: flex;
  margin: 24px 0;
  text-align: center;
}
.home div.download .btns div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex: 1;
}
.home div.download .btns div a {
  margin: 0 auto;
}
.home div.download .btns div img {
  height: 40px;
  margin: 0 auto;
}
.home div.download .description {
  padding: 24px 16px;
}

@media screen and (min-width: 768px) {
  .home {
    flex-direction: row;
  }
  .home div.illustration img {
    width: 200px !important;
  }
  .home div.download {
    justify-content: center;
  }
  .home div.download .description {
    padding: 24px 0px;
  }
  .home div.download .content {
    padding: 0 24px;
  }
  .home div.download h1 {
    font-size: 32px;
  }
}
</style>
